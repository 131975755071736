import { card } from "creditcards";
import { DateTime } from "luxon";

export class CreditCardUtils {
    static getCardType(cardNumber: string): "Mastercard" | "Visa" | "Discover" | "AmericanExpress" | "Unknown" {
        // Use the creditcards package to identify the card type
        const cleanedCardNumber = cardNumber.replace(/\s+/g, "");
        const cardType = card.type(cleanedCardNumber);

        // Remove any spaces from the returned card type
        const cleanedCardType = cardType?.replace(/\s+/g, "") ?? ``;

        // Check if the card type is one of the specified values
        if (["Mastercard", "Visa", "Discover", "AmericanExpress"].includes(cleanedCardType)) {
            return cleanedCardType as "Mastercard" | "Visa" | "Discover" | "AmericanExpress";
        } else {
            return "Unknown";
        }
    }

    static isCardValid(cardNumber: string, cardType?: string): boolean {
        // Use the creditcards package to validate the card number
        const cleanedCardNumber = cardNumber.replace(/\s+/g, "");
        return card.isValid(cleanedCardNumber, cardType);
    }

    static formatCardExpiration(expiration: string) {
        // Validate the input string
        if (expiration.length !== 5 || expiration[2] !== "/") {
            return null;
        }

        // Split the string to get the month and year
        const [mm, yy] = expiration.split("/");

        // Convert to month string
        const monthMap: Record<string, string> = {
            "01": "January",
            "02": "February",
            "03": "March",
            "04": "April",
            "05": "May",
            "06": "June",
            "07": "July",
            "08": "August",
            "09": "September",
            "10": "October",
            "11": "November",
            "12": "December"
        };

        const month = !!mm ? monthMap[mm] : "None";

        // Convert to four-digit year
        const currentYear = new Date().getFullYear();
        const currentCentury = Math.floor(currentYear / 100) * 100;
        const year = !!yy ? currentCentury + parseInt(yy, 10) : 0;

        // Construct the object
        return {
            month: month as "None" | "January" | "February" | "March" | "April" | "May" | "June" | "July" | "August" | "September" | "October" | "November" | "December",
            year
        };
    }

    static isValidExpirationDate(expiration: string): boolean {
        // Validate the format is MM/YY
        const regex = /^(\d{2})\/(\d{2})$/;
        const match = expiration.match(regex);

        if (match === null) {
            return false;
        }

        const monthStr = match[1];
        const yearStr = match[2];

        if (typeof monthStr === "undefined" || typeof yearStr === "undefined") {
            return false;
        }

        // Safe to proceed with non-undefined strings for parsing
        const month = parseInt(monthStr, 10);
        const year = parseInt(yearStr, 10);

        const currentCentury = Math.floor(DateTime.now().year / 100) * 100;
        const expYear = currentCentury + year;

        if (month < 1 || month > 12) {
            // Invalid month
            return false;
        }

        // Create a DateTime object for the last moment of the expiration month and year
        const expirationDate = DateTime.local(expYear, month).endOf("month");

        // Compare the current DateTime to the expiration DateTime
        return DateTime.now() <= expirationDate;
    }
}
