import { PetQuote, PetUnderwriterType } from "spot-types/entities/PetQuote";
import {
    CoverageSettings,
    Policy,
    Quote,
    PartialPolicySchema,
    QuoteSchema,
    Pet,
    PetSchema,
    PartialBillingStepSchema,
    DefaultPolicyAmounts,
    PartialPetSchema
} from "@/shared/types/Quote.interface";
import { PetPolicy } from "spot-types/entities/PetPolicy";
import { DateTime } from "luxon";
import { Coverage, CoverageType, CoverageTypeDifference } from "spot-types/entities/Coverage";
import { z } from "zod";
import { Species } from "spot-types/entities/Species";
import isNumber from "lodash/isNumber";
import { phone } from "phone";
import { PublicConfig } from "../PublicConfig";

const TMP_POLICY_PREFIX = "tmp:";

export class QuoteDataUtils {
    static getUnderwriterFromFormId(formId: string | undefined): PetUnderwriterType {
        if (!formId) throw new Error(`formId is required`);
        if (formId.toLowerCase().includes("ptz-us")) {
            return "ptz-us";
        }
        if (formId.toLowerCase().includes("ptz-ca")) {
            return "ptz-ca";
        }
        throw new Error(`Unknown formId: ${formId}`);
    }

    static getBillingStepInitialValues(quote?: Quote): z.infer<typeof PartialBillingStepSchema> {
        const defaultAddress = {
            street1: "",
            street2: "",
            city: "",
            state: "",
            zipCode: ""
        };
        const defaultCreditCard = {
            nameOnCard: "",
            number: "",
            expiration: "",
            cvv: ""
        };

        // Destructure existing values from quote
        const { billingInfo } = quote || {};

        const firstName = quote?.firstName;
        const lastName = quote?.lastName;
        const isPetParent = firstName?.toLowerCase() === "pet" && lastName?.toLowerCase() === "parent";
        const initialNameOnCard = isPetParent ? "" : `${quote?.firstName} ${quote?.lastName}`;

        return {
            billingInfo: {
                firstName: isPetParent ? "" : quote?.firstName || "",
                lastName: isPetParent ? "" : quote?.lastName || "",
                address: {
                    ...defaultAddress,
                    ...billingInfo?.address
                },
                creditCard: {
                    ...defaultCreditCard,
                    ...billingInfo?.creditCard,
                    nameOnCard: !!billingInfo?.creditCard?.nameOnCard ? billingInfo.creditCard.nameOnCard : initialNameOnCard
                },
                frequency: billingInfo?.frequency || "monthly"
            },
            phone: quote?.phone || "",
            ratingAddress: { ...defaultAddress, ...billingInfo?.address, ...quote?.ratingAddress },
            paperless: true
        };
    }

    static createNewPolicy(): z.infer<typeof PartialPetSchema> {
        return {
            name: ``,
            species: Species.Dog,
            gender: `F`,
            age: undefined,
            birthMonth: "",
            birthYear: "",
            breedID: ``
        };
    }

    static createNewPolicyUS(currentSettings?: CoverageSettings): z.infer<typeof PartialPetSchema> {
        return {
            name: ``,
            species: Species.Dog,
            gender: `F`,
            age: undefined,
            breedID: ``,
            coverageSettings: currentSettings ?? {
                coverages: [
                    { type: "accident", id: "Level2~Deductible500~Copay30" },
                    { type: "illness", id: "Level2~Deductible500~Copay30" }
                ],
                amounts: { reimbursementRate: 70, annualDeductible: 500, annualLimit: 2500 },
                extra: {}
            }
        };
    }

    static isValidPhoneNumber(phoneNumber: string): boolean {
        const isValid = phone(phoneNumber).isValid;
        return isValid;
    }

    static isValidQuote(quote: Quote | undefined): boolean {
        if (!quote) {
            return false;
        }
        const allPoliciesValid = (quote.policies ?? []).every(QuoteDataUtils.isValidPolicy);

        return !!quote.email && !!quote.ratingZipcode && allPoliciesValid;
    }

    static isValidPolicy(policy: Policy): boolean {
        const { name, species, breedID, gender, age, extra } = policy;
        return PartialPolicySchema.safeParse(policy).success && QuoteDataUtils.isValidPet({ name, species, breedID, gender, age, extra } as Pet);
    }

    static isValidPet(pet?: Pet): boolean {
        return PetSchema.safeParse(pet).success;
    }

    static isValidPurchase(quote: Quote | undefined, debug?: boolean) {
        if (debug) {
            try {
                return !!QuoteSchema.parse(quote);
            } catch (err) {
                if (err instanceof z.ZodError) {
                    console.log(err.issues);
                }
                return false;
            }
        }
        return QuoteSchema.safeParse(quote).success;
    }

    private static checkSpecies(species: "Dog" | "Cat", str?: string): string | undefined {
        if (!str) return;
        if (species === "Dog" && str.includes("_CAT")) {
            return str.replace(/_CAT/g, "_DOG");
        }
        if (species === "Cat" && str.includes("_DOG")) {
            return str.replace(/_DOG/g, "_CAT");
        }
        return str;
    }

    static quoteToPetQuote(quote: Quote): PetQuote {
        const petQuote: PetQuote = {
            underwriter: quote.underwriter,
            quoteId: quote.id,
            accountID: quote.accountID,
            firstName: quote.firstName,
            lastName: quote.lastName,
            email: quote.email,
            phone: quote.phone,
            discountCode: quote.discountCode ?? ``,
            affiliateCode: quote.affiliateCode ?? ``,
            billingInfo: quote.billingInfo,
            ratingAddress: quote.ratingAddress,
            marketing: quote.marketing,
            extra: quote.extra
        };

        if (!!quote.paperless) {
            petQuote.paperless = quote.paperless;
        }

        if (!!quote.ratingZipcode && quote.ratingZipcode !== quote?.ratingAddress?.zipCode) {
            petQuote.ratingAddress = {
                zipCode: quote.ratingZipcode
            };
        } else if (!petQuote.ratingAddress && quote.ratingZipcode) {
            petQuote.ratingAddress = {
                zipCode: quote.ratingZipcode
            };
        }

        if (quote.transactionFee && quote.transactionFee.value !== undefined && isNumber(quote.transactionFee.value) && quote.transactionFee.symbol !== undefined) {
            petQuote.transactionFee = {
                value: quote.transactionFee.value,
                symbol: quote.transactionFee.symbol
            };
        }

        petQuote.extra = {
            ...petQuote.extra,
            ...quote.extra
        };

        if (quote.lastStepID !== undefined) {
            petQuote.extra.lastStepID = quote.lastStepID.toString();
            petQuote.extra.lastStepIDUpdatedAt = DateTime.utc().toISO();
        }

        if (quote.extra?.policyStepConsented) {
            petQuote.marketing = {};
            petQuote.marketing.hasEmailConsent = true;
            petQuote.marketing.hasSMSConsent = true;
        }

        if (quote.policies) {
            petQuote.pets = quote.policies.map(policy => {
                const petPolicy: PetPolicy = {
                    type: "pet-policy",
                    petID: policy?.id,
                    species: policy.species as any,
                    gender: policy.gender,
                    name: policy.name,
                    breedID: policy.breedID
                };

                if (policy.extra) {
                    petPolicy.extra = {
                        ...policy.extra
                    };
                }

                // If this is a temp pet ID, go ahead and remove it before we send it to the API
                if (!petPolicy?.petID || petPolicy.petID?.startsWith(TMP_POLICY_PREFIX)) {
                    delete petPolicy["petID"];
                }

                if (policy.birthMonth && policy.birthYear) {
                    petPolicy.birthDate = DateTime.utc(parseInt(policy.birthYear), parseInt(policy.birthMonth), 1).toISODate() ?? DateTime.utc().toISODate();
                } else if (typeof policy.age === "number") {
                    const age = policy.age === 0 ? 10 / 12 : policy.age;
                    petPolicy.birthDate = DateTime.utc().minus({ year: age }).toISODate() ?? DateTime.utc().toISODate();
                }

                const coverages = policy.coverageSettings?.coverages;
                const reimbursementRate = policy.coverageSettings?.amounts?.reimbursementRate;
                const annualDeductible = policy.coverageSettings?.amounts?.annualDeductible;
                const annualLimit = policy.coverageSettings?.amounts?.annualLimit;

                if (coverages) {
                    const baseCoverage: Coverage = {
                        type: [],
                        details: {},
                        extra: {}
                    };

                    // Fill in details for reimbursementRate, deductible, and annualLimit...

                    const accidentCoverage = coverages.find(c => c.type === "accident");
                    const illnessCoverage = coverages.find(c => c.type === "illness");

                    if ((accidentCoverage || illnessCoverage) && Array.isArray(baseCoverage.type)) {
                        if (accidentCoverage?.type === `accident`) {
                            baseCoverage.type.push("accident");
                            baseCoverage.id = this.checkSpecies(policy.species as Species, accidentCoverage?.id);
                            baseCoverage.extra = accidentCoverage.extra;
                        }
                        if (illnessCoverage?.type === `illness`) {
                            baseCoverage.type.push("illness");
                            // No need to update id and extra since they are the same as accidentCoverage
                        }
                        petPolicy.coverages = [baseCoverage];
                    }
                    if (reimbursementRate !== undefined) {
                        baseCoverage.details!.reimbursementPercent = {
                            value: reimbursementRate,
                            symbol: "%"
                        };
                    }

                    if (annualDeductible !== undefined) {
                        baseCoverage.details!.deductible = {
                            value: annualDeductible,
                            symbol: "$"
                        };
                    }

                    if (annualLimit !== undefined) {
                        baseCoverage.details!.annualLimit = {
                            value: annualLimit,
                            symbol: annualLimit === PublicConfig.UNLIMITED_ANNUAL_LIMIT_VALUE ? "Unlimited" : "$"
                        };
                    }

                    petPolicy.coverages = [baseCoverage];

                    if (coverages.some(coverage => coverage.type === "wellness-gold")) {
                        const wellnessGoldCoverage = coverages.find(coverage => coverage.type === "wellness-gold");
                        petPolicy.coverages.push({
                            type: ["wellness"],
                            id: this.checkSpecies(policy.species as Species, wellnessGoldCoverage?.id),
                            extra: wellnessGoldCoverage?.extra
                        });
                    } else if (coverages.some(coverage => coverage.type === "wellness-platinum")) {
                        const wellnessPlatinumCoverage = coverages.find(coverage => coverage.type === "wellness-platinum");
                        petPolicy.coverages.push({
                            type: ["wellness"],
                            id: this.checkSpecies(policy.species as Species, wellnessPlatinumCoverage?.id),
                            extra: wellnessPlatinumCoverage?.extra
                        });
                    }
                }

                return petPolicy;
            });
        }

        return petQuote;
    }

    static getDefaultAmountsByUnderwriter(underwriter: PetUnderwriterType): DefaultPolicyAmounts | null {
        switch (underwriter) {
            case "ptz-us":
                return {
                    annualLimit: PublicConfig.PTZ_US.DEFAULT_ANNUAL_LIMIT,
                    annualDeductible: PublicConfig.PTZ_US.DEFAULT_ANNUAL_DEDUCTIBLE,
                    reimbursementRate: PublicConfig.PTZ_US.DEFAULT_REIMBURSMENT_RATE
                };
            case "ptz-ca":
                return {
                    annualLimit: PublicConfig.PTZ_CA.DEFAULT_ANNUAL_LIMIT
                };
            default:
                return null;
        }
    }

    static extractStartDateByCoverageType(type: CoverageType, coverageTypeDifferences?: CoverageTypeDifference[]): string | undefined {
        const typeDifferenceToCheck = coverageTypeDifferences?.find(coverageTypeDifference => coverageTypeDifference.type === type);
        return typeDifferenceToCheck?.startDate;
    }

    static extractCoveragesFromPetPolicy(pet: PetPolicy, underwriter?: PetUnderwriterType): CoverageSettings | undefined {
        const coverageSettings: CoverageSettings = {
            coverages: [],
            amounts: {},
            extra: {}
        };

        let defaultAmounts = null;

        if (underwriter) {
            defaultAmounts = QuoteDataUtils.getDefaultAmountsByUnderwriter(underwriter);
        }

        if (pet.coverages) {
            for (const coverage of pet.coverages) {
                const coverageNeedsDefaults = !coverage.id;

                if (coverage.details?.reimbursementPercent?.value) {
                    coverageSettings.amounts!.reimbursementRate = coverage.details.reimbursementPercent.value;
                }

                if (coverage.details?.deductible?.value) {
                    coverageSettings.amounts!.annualDeductible = coverage.details.deductible.value;
                }

                if (coverage.details?.annualLimit?.value) {
                    coverageSettings.amounts!.annualLimit = coverage.details.annualLimit.value;
                }

                const hasAccidentCoverage = coverage.type?.includes("accident");
                if (hasAccidentCoverage || (!hasAccidentCoverage && coverageNeedsDefaults)) {
                    coverageSettings.coverages!.push({
                        type: "accident",
                        id: coverage?.id,
                        name: coverage?.name,
                        extra: coverage?.extra,
                        createDate: coverage?.createDate,
                        startDate: this.extractStartDateByCoverageType("accident", coverage?.coverageTypeDifferences)
                    });
                }

                const hasIllnessCoverage = coverage.type?.includes("illness");
                if (hasIllnessCoverage || (!hasIllnessCoverage && coverageNeedsDefaults)) {
                    coverageSettings.coverages!.push({
                        type: "illness",
                        id: coverage?.id,
                        name: coverage?.name,
                        extra: coverage?.extra,
                        createDate: coverage?.createDate,
                        startDate: this.extractStartDateByCoverageType("illness", coverage?.coverageTypeDifferences)
                    });
                }

                const hasPerilIllnessCoverage = coverage.type?.includes("peril");
                if (hasPerilIllnessCoverage) {
                    coverageSettings.coverages!.push({
                        type: "peril",
                        id: coverage?.id,
                        name: coverage?.name,
                        extra: coverage?.extra,
                        createDate: coverage?.createDate,
                        startDate: this.extractStartDateByCoverageType("peril", coverage?.coverageTypeDifferences)
                    });
                }

                if (coverage.type?.includes("wellness")) {
                    if (coverage.name === "Wellness Care Routine - WCR" || coverage.name === "Wellness Gold") {
                        coverageSettings.coverages!.push({
                            type: "wellness-gold",
                            id: coverage?.id,
                            name: coverage?.name,
                            extra: coverage?.extra,
                            createDate: coverage?.createDate,
                            startDate: this.extractStartDateByCoverageType("wellness", coverage?.coverageTypeDifferences),
                            preventivePrice: coverage.basePrice?.value
                        });
                    } else if (coverage.name === "Wellness Care Advanced - WCA" || coverage.name === "Wellness Platinum") {
                        coverageSettings.coverages!.push({
                            type: "wellness-platinum",
                            id: coverage?.id,
                            name: coverage?.name,
                            extra: coverage?.extra,
                            createDate: coverage?.createDate,
                            startDate: this.extractStartDateByCoverageType("wellness", coverage?.coverageTypeDifferences),
                            preventivePrice: coverage.basePrice?.value
                        });
                    }
                }

                if (coverage.extra) {
                    coverageSettings.extra = {
                        ...coverageSettings.extra,
                        ...coverage.extra
                    };
                }
            }
            // Set default amounts only if no coverage amounts were found/set above
            if (!coverageSettings.amounts?.annualLimit && defaultAmounts?.annualLimit) {
                coverageSettings.amounts!.annualLimit = defaultAmounts.annualLimit;
            }

            if (!coverageSettings.amounts?.annualDeductible && defaultAmounts?.annualDeductible) {
                coverageSettings.amounts!.annualDeductible = defaultAmounts.annualDeductible;
            }

            if (!coverageSettings.amounts?.reimbursementRate && defaultAmounts?.reimbursementRate) {
                coverageSettings.amounts!.reimbursementRate = defaultAmounts.reimbursementRate;
            }
        }
        return coverageSettings;
    }

    static petQuoteToQuote(petQuote: PetQuote, underwriter?: PetUnderwriterType, includeBirthProps?: boolean): Quote {
        const quote: Quote = {
            underwriter: petQuote?.underwriter,
            id: petQuote.quoteId,
            accountID: petQuote?.accountID,
            firstName: petQuote?.firstName,
            lastName: petQuote?.lastName,
            email: petQuote.email,
            phone: petQuote.phone,
            ratingZipcode: petQuote.ratingAddress?.zipCode,
            discountCode: petQuote?.discountCode ?? ``,
            affiliateCode: petQuote?.affiliateCode ?? ``,
            transactionFee: petQuote?.transactionFee,
            billingInfo: petQuote?.billingInfo,
            ratingAddress: petQuote?.ratingAddress,
            extra: petQuote?.extra,
            quoteStatus: petQuote?.quoteStatus,
            marketing: petQuote?.marketing
        };

        if (!!petQuote.paperless) {
            quote.paperless = petQuote.paperless;
        }

        if (petQuote.marketing?.hasEmailConsent) {
            quote.extra = {
                ...quote.extra,
                policyStepConsented: true
            };
        }

        if (petQuote.marketing?.hasSMSConsent) {
            quote.extra = {
                ...quote.extra,
                policyStepConsented: true
            };
        }

        if (petQuote.pets && petQuote.pets.length > 0) {
            quote.policies = petQuote.pets.map(petPolicy => {
                const policy: Policy = {
                    id: petPolicy.petID,
                    name: petPolicy.name,
                    breedID: petPolicy.breedID,
                    gender: petPolicy.gender as "M" | "F" | undefined,
                    species: petPolicy.species
                };

                if (petPolicy.extra) {
                    policy.extra = {
                        ...petPolicy.extra
                    };
                }

                if (petPolicy.birthDate) {
                    const birthDate = DateTime.fromISO(petPolicy.birthDate);
                    if (includeBirthProps) {
                        policy.birthMonth = birthDate.month.toString();
                        policy.birthYear = birthDate.year.toString();
                    }
                    policy.age = Math.floor(-birthDate.diff(DateTime.utc().endOf("day"), "years").years);
                }

                if (petPolicy.coverages) {
                    let basePrice = 0;
                    let discountAmount = 0;
                    for (const coverage of petPolicy.coverages) {
                        if (coverage.basePrice?.value) {
                            basePrice += coverage.basePrice.value;
                        }

                        if (coverage.taxAmount?.value) {
                            basePrice += coverage.taxAmount.value;
                        }

                        if (coverage.discountAmount?.value) {
                            discountAmount += coverage.discountAmount.value;
                        }

                        if (coverage.discounts?.length) {
                            policy.discounts = coverage.discounts;
                        }
                    }

                    const finalPrice = basePrice - discountAmount;

                    if (finalPrice > 0) {
                        policy.basePrice = {
                            value: basePrice,
                            symbol: "$"
                        };

                        policy.discountAmount = {
                            value: discountAmount,
                            symbol: "%"
                        };

                        const wellnessTotal = petPolicy.coverages.find(c => c.type?.includes("wellness"))?.basePrice?.value ?? 0;
                        policy.discountTotalPercentage = discountAmount / (basePrice - wellnessTotal);

                        policy.finalPrice = {
                            value: finalPrice,
                            symbol: "$"
                        };
                    }
                }

                policy.coverageSettings = QuoteDataUtils.extractCoveragesFromPetPolicy(petPolicy, underwriter);

                return policy;
            });
        }

        if (petQuote.extra?.lastStepID) {
            quote.lastStepID = petQuote.extra.lastStepID;
        }

        return quote;
    }
}
