import { z } from "zod";
import { RegexUtils } from "../utils/RegexUtils";
import { CreditCardUtils } from "../utils/CreditCardUtils";
import Strings from "../utils/Strings.constants";

export const CreditCardSchema = z
    .object({
        nameOnCard: z.string().regex(RegexUtils.firstLastRegex, `Must include a first and last name.`).min(3, `Required`).max(101, `Too Long!`),
        number: z
            .string()
            .regex(RegexUtils.creditCardRegex, `Invalid card number format`)
            .refine(cardNumber => CreditCardUtils.isCardValid(cardNumber), {
                message: "Invalid card number" // Custom error message
            }),
        expiration: z
            .string()
            .length(5, `Must be MM/YY format`)
            .refine((expirationDate: string) => CreditCardUtils.isValidExpirationDate(expirationDate), {
                message: "Invalid exp date" // Custom error message for expiration date validation
            }),
        cvv: z.string().regex(/^\d{3,4}?$/, Strings.PTZ_US.ERROR.INVALID_CVV_CODE)
    })
    .partial();

export type CreditCard = z.infer<typeof CreditCardSchema>;
