// Shared strings used across forms
export default class Strings {
    static PET_AGE = "Age *";
    static PET_NAME = "Pet Name *";
    static ADD_PET = "Add Another Pet";
    static ENTER_PET_INFO = "Enter Pet Info";
    static SELECT_MONTH = " Birth Month *";
    static SELECT_YEAR = " Birth Year *";
    static SELECT_BREED = "Breed *";
    static FIRST_NAME = "First Name *";
    static LAST_NAME = "Last Name *";
    static EMAIL_ADDRESS = "Email Address *";
    static ZIP_CODE = "Zip Code *";
    static CALCULATING = "Calculating...";
    static ANNUAL_BILLING_LABEL = "Annual billing";
    static MULTIPET_LINKED_LABEL = "Apply to all pets";
    static MULTIPET_TOGGLE_HINT = "Click or tap names to toggle between pets";
    static NOT_APPLICABLE = "N/A";
    static THANKS_JOINING = "Thanks for joining the pack!";
    static PROBLEM_FETCH_QUOTE = `We’re having trouble fetching your quote.`;
    static CLOSE_NOTIFICATION = "Close notification";
    static CREATE_PLAN = "Create Your Plan";
    static PROCEED_TO_CHECKOUT = "Proceed to Checkout";
    static PTZ_US = {
        META_TITLE: "Free Pet Insurance Quote | Pick Your Coverage",
        META_DESCRIPTION:
            "Spot is proud to offer pet parents a free insurance pet insurance quote. Visit us to learn more about our different coverage options and what is best for you.",
        COPYRIGHT_TEXT: "%year% United States Fire Insurance Company. Spot Pet Insurance Logo, Copyright %year%, Spot Pet Insurance. All Rights Reserved.",
        SELECT_COVERAGE_TEXT: "Select Coverage",
        BILLING_NAME: "Name on card",
        BILLING_ADDRESS_1: "Address 1",
        BILLING_ADDRESS_2: "Address 2",
        CREDIT_CARD: "Credit Card",
        CREDIT_EXPIRES: "Expires",
        CREDIT_CVV: "CVV",
        PHONE_NUMBER: "18009051595",
        HOURS: "Mon – Fri | 8am – 8pm EST",
        // DISPLAY_NUMBER: "888-880-SPOT",
        PLAN_PDF_LINK_TEXT: "View Plan Details",
        GIFTCARD: {
            REWARD: "$25 Amazon Gift Card",
            UNAVAILABLE: "Amazon Gift Card* Unavailable in Your State",
            APPLIED: "$25 Amazon Gift Card Applied!*",
            APPLY: "Apply and get a",
            SIGN_UP: "Sign up and get a",
            QUALIFY: "See if you qualify for a"
        },
        ERROR: {
            INVALID_CVV_CODE: "Invalid CVV code"
        }
    };
    static PTZ_CA = {
        META_TITLE: "Free Pet Insurance Quote | Spot Pet Insurance (Canada)",
        META_DESCRIPTION: "Get your free pet insurance quote. Spot Pet Insurance offers personalized pet plan options for dogs and cats.",
        COPYRIGHT_TEXT: "%year% All Rights Reserved. Spot Pet Insurance Logo, Copyright %year%, Spot Pet Insurance.",
        SELECT_COVERAGE_TEXT: "Select Coverage",
        PHONE_NUMBER: "18885017768",
        HOURS: `Mon – Fri | 8am – 8pm EST \n Sat | 9am – 5pm EST`,
        POSTAL_CODE: "Postal code *",
        POSTAL_CODE_PLACEHOLDER: "V5R 4B3",
        // DISPLAY_NUMBER: "888-501-SPOT",
        PLAN_PDF_LINK_TEXT: "View Plan Details"
    };
}
