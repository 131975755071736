import { ReactNode } from "react";
import { z } from "zod";
import { Species } from "spot-types/entities/Species";
import { RegexUtils } from "../utils/RegexUtils";
import { CreditCardSchema } from "./CreditCard.interface";
import { notificationSchema } from "./Notifications";

// Schema Definitions

export const UnknownExtraSchema = z.record(z.unknown());

const AmountSchema = z.object({
    value: z.number(),
    symbol: z.string()
});

const CoverageTypeEnumSchema = z.enum(["accident", "illness", "peril", "wellness-gold", "wellness-platinum"]);

const CoverageAmountsSchema = z.object({
    reimbursementRate: z.number(),
    annualDeductible: z.number(),
    annualLimit: z.number()
});

const CoverageDiscountSchema = z.object({
    id: z.string().optional(),
    name: z.string().optional(),
    adjustment: z.number(),
    amount: z.number()
});

const CoverageTypeDifferencesSchema = z.object({
    type: CoverageTypeEnumSchema,
    startDate: z.string().optional()
});

const ExtraCoverageSettingsSchema = z
    .object({
        recId: z.number()
    })
    .deepPartial();

const CoverageTypeSchema = z.object({
    type: CoverageTypeEnumSchema,
    id: z.string().optional(),
    name: z.string().optional(),
    createDate: z.string().optional(),
    startDate: z.string().optional(),
    extra: ExtraCoverageSettingsSchema.and(UnknownExtraSchema).optional(),
    preventivePrice: z.number().optional()
});

const CoverageSettingsSchema = z.object({
    coverages: z.array(CoverageTypeSchema),
    amounts: CoverageAmountsSchema,
    extra: UnknownExtraSchema.optional()
});

const PartialCoverageSettingsSchema = CoverageSettingsSchema.deepPartial();

const ZipCodeOrPostalCodeSchema = z.string().refine(value => RegexUtils.zipRegex.test(value) || RegexUtils.postalCodeCARegex.test(value), {
    message: "Zip code not valid"
});

const AddressSchema = z.object({
    street1: z.string().min(1, `Required`).max(255, `Too Long!`).regex(RegexUtils.hasLettersRegex, `Address must contain characters`),
    street2: z.string().optional(),
    city: z.string().min(1, `Required`).max(100, `Too Long!`).regex(RegexUtils.hasLettersRegex, `City must contain characters`),
    state: z.string().min(2, `Required`).max(14, `Too Long!`).regex(RegexUtils.hasLettersRegex, `State must contain characters`),
    zipCode: ZipCodeOrPostalCodeSchema,
    country: z.string().optional()
});

const MarketingSchema = z.object({
    hasSMSConsent: z.boolean().optional(),
    hasEmailConsent: z.boolean().optional()
});

const BillingFrequency = z.enum(["monthly", "yearly"]);
export type BillingFrequencyEnum = z.infer<typeof BillingFrequency>;

export const BillingInfoSchema = z.object({
    firstName: z
        .string()
        .regex(RegexUtils.noEmptyStringRegex, `First name must contain characters`)
        .min(2, `First name cannot be less than 2 characters long`)
        .max(255, `Too Long!`),
    lastName: z.string().regex(RegexUtils.noEmptyStringRegex, `Last name must contain characters`).min(2, `Last name cannot be less than 2 characters long`).max(255, `Too Long!`),
    address: AddressSchema,
    creditCard: CreditCardSchema,
    frequency: BillingFrequency
});

const ExtraPetSchema = z
    .object({
        microchipID: z
            .string()
            .optional()
            .refine(
                value => {
                    if (value === undefined || value === "") return true;
                    return RegexUtils.microchipRegex.test(value);
                },
                {
                    message: "Microchip number must be between 8 - 15 digits"
                }
            )
    })
    .deepPartial();

export const PetSchema = z.object({
    id: z.string().optional(),
    name: z
        .string()
        .regex(RegexUtils.noEmptyStringRegex, `Pet name must contain characters`)
        .regex(RegexUtils.petNameRegex, `Pet name can only contain letters and numbers`)
        .min(1, `Required`)
        .max(50, `Pet name cannot be more than 50 characters long`),
    species: z.nativeEnum(Species),
    breedID: z.string().min(1, `Required`),
    gender: z.enum([`M`, `F`]),
    age: z.number(),
    birthMonth: z.string().min(1, `Birth month is required`).optional(),
    birthYear: z.string().min(4, `Birth year is required`).optional(),
    coverageSettings: PartialCoverageSettingsSchema.optional(),
    extra: ExtraPetSchema.and(UnknownExtraSchema).optional()
});

export const PartialPetSchema = PetSchema.deepPartial();

export const PolicySchema = PetSchema.extend({
    basePrice: AmountSchema,
    discountAmount: AmountSchema.optional(),
    finalPrice: AmountSchema,
    coverageSettings: CoverageSettingsSchema,
    discounts: z.array(CoverageDiscountSchema).optional(),
    discountTotalPercentage: z.number().optional()
});

export const PartialPolicySchema = PetSchema.merge(PolicySchema.deepPartial());

export const OwnerSchema = z.object({
    firstName: z
        .string()
        .regex(RegexUtils.noEmptyStringRegex, `First name must contain characters`)
        .min(2, `First name cannot be less than 2 characters long`)
        .max(255, `Too Long!`),
    lastName: z.string().regex(RegexUtils.noEmptyStringRegex, `Last name must contain characters`).min(2, `Last name cannot be less than 2 characters long`).max(255, `Too Long!`),
    email: z.string().email(`Enter a valid email address`),
    ratingZipcode: ZipCodeOrPostalCodeSchema
});

const ExtraQuoteSchema = z
    .object({
        lastStepID: z.string(),
        thankYouURL: z.string(),
        paymentURL: z.string(),
        policyStepConsented: z.boolean(),
        termsConsent: z.boolean(),
        modalStates: z.record(z.any()),
        formData: z.record(z.any()),
        notifications: notificationSchema.array().optional(),
        rewards: z.any().optional(),
        saasquatch: z.record(z.any()).optional()
    })
    .deepPartial();

export const QuoteSchema = OwnerSchema.extend({
    id: z.string().regex(RegexUtils.uuidRegex),
    underwriter: z.enum([`ptz-us`, `ptz-ca`]),
    phone: z.string().regex(RegexUtils.phoneRegex, `Enter a valid mobile number`).optional(),
    policies: z.array(PolicySchema),
    lastStepID: z.string().optional(),
    billingInfo: BillingInfoSchema,
    discountCode: z.string().nullable().optional(),
    affiliateCode: z.string().nullable().optional(),
    quoteStatus: z.enum([`open`, `failed-authorization`, `finalized`]),
    transactionFee: AmountSchema.optional(),
    accountID: z.string().optional(),
    ratingAddress: AddressSchema,
    marketing: MarketingSchema.optional(),
    paperless: z.boolean().optional(),
    extra: ExtraQuoteSchema.and(UnknownExtraSchema).optional()
});
export const PartialQuoteSchema = QuoteSchema.deepPartial();

export const BillingStepSchema = z.object({
    billingInfo: BillingInfoSchema,
    ratingAddress: AddressSchema,
    phone: z.string().regex(RegexUtils.phoneRegex, `Enter a valid mobile number`),
    paperless: z.boolean(),
    extra: z.object({
        termsConsent: z.coerce.boolean().refine(value => value === true, {
            message: "Please agree to contact consent"
        })
    })
});
export const PartialBillingStepSchema = BillingStepSchema.deepPartial();

// Underwriter Schema
export const UnderwriterSchema = z.enum([`ptz-us`, `ptz-ca`]);
export const UnderwriterConfigSchema = z.enum([`PTZ_US`, `PTZ_CA`]);

// Types
export type CoverageType = z.infer<typeof CoverageTypeSchema>;
export type CoverageTypeEnum = z.infer<typeof CoverageTypeEnumSchema>;
export type CoverageAmounts = z.infer<typeof CoverageAmountsSchema>;
export type CoverageSettings = z.infer<typeof PartialCoverageSettingsSchema>;
export type BillingInfo = z.infer<typeof BillingInfoSchema>;
export type Owner = z.infer<typeof OwnerSchema>;
export type Pet = z.infer<typeof PetSchema>;
export type Policy = z.infer<typeof PartialPolicySchema>;
export type PolicyFinalized = z.infer<typeof PolicySchema>;
export type Quote = z.infer<typeof PartialQuoteSchema>;
export type QuoteFinalized = z.infer<typeof QuoteSchema>;
export type UnderwriterConfigKey = z.infer<typeof UnderwriterConfigSchema>;
export interface Option {
    label: string;
    value: any;
    icon?: ReactNode;
}

export type FormattedPolicy = Policy & {
    petAge: string;
    discountsAmount: string;
    annualLimit: string;
    annualDeductible: string;
    reimbursementRate: string;
    accidentCreateDate: string;
    accidentCoverageDate: string;
    illnessCoverageDate: string;
    perilIllnessCoverageDate: string;
    wellnessCoverageDate?: string;
    wellnessCoveragePrice?: number;
    hasWellnessCoverage: boolean;
    isAccidentOnly: boolean;
};

export type DefaultPolicyAmounts = { annualLimit?: number; annualDeductible?: number; reimbursementRate?: number };

export type KeyValuePair = {
    key: string;
    value?: string | null;
};
